.spinner span {
  -webkit-animation: bounce-delay 1.2s infinite ease-in-out both;
  animation: bounce-delay 1.2s infinite ease-in-out both;
}

.spinner #dots-1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner #dots-2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes bounce-delay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes bounce-delay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
