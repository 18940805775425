.input-has-error input {
  background-color: rgba(255, 39, 80, 0.1) !important;
}

input + span {
  display: none;
}

input:focus + span {
  display: inline;
}

.input-wrapper:focus-within input {
  background-color: rgba(0, 197, 114, 0.1);
}
